import { render } from "./MessagingConversationsComponent.vue?vue&type=template&id=9e8db79e"
import script from "./MessagingConversationsComponent.vue?vue&type=script&lang=js"
export * from "./MessagingConversationsComponent.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "9e8db79e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9e8db79e', script)) {
    api.reload('9e8db79e', script)
  }
  
  module.hot.accept("./MessagingConversationsComponent.vue?vue&type=template&id=9e8db79e", () => {
    api.rerender('9e8db79e', render)
  })

}

script.__file = "src/components/MessagingConversationsComponent.vue"

export default script