<template>
  <div class="py-4 max-w-2xl mx-auto">
    <div
      class="bg-white border-t-2 border-gray-300 text-black text-xl p-3 text-center"
      v-if="conversations.length == 0"
    >
      No conversations yet.
    </div>
    <template v-else>
      <div
        v-for="conversation in conversations"
        :key="conversation.phoneNumber"
        class="conversation shadow flex justify-start p-4 mb-4 cursor-pointer"
      >
        <router-link
          :to="`/messaging/conversation/${conversation.id}`"
          class="flex"
        >
          <div
            class="chat-icon bg-blue-900 rounded-full h-20 w-20 flex items-center justify-center"
          >
            <svg
              class="h-16 w-16 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
              />
            </svg>
          </div>
          <div class="flex flex-col justify-between ml-6">
            <div
              v-if="conversation.customer_number == conversation.customer_label"
              class="label font-bold text-black text-lg"
            >
              {{ conversation.customer_label }}
            </div>
            <div v-else class="label font-bold text-black text-lg">
              {{ conversation.customer_label }} (
              {{ conversation.customer_number }} )
            </div>
            <div class="last-message text-black">
              {{ conversation.last_message }}
            </div>
            <div class="timestamp text-sm">
              {{
                conversation.last_message_timestamp &&
                  transformDate(conversation.last_message_timestamp.seconds)
              }}
            </div>
          </div>
        </router-link>

        <div class="flex-1 flex items-center justify-end">
          <div
            v-if="conversation.unread_count != 0"
            class="unread-indicator bg-red-600 rounded-full h-7 w-7 flex items-center justify-center text-white"
          >
            {{ conversation.unread_count }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");
const { DateTime } = require("luxon");
export default {
  name: "MessagingConversationsComponent",
  data: () => ({
    conversations: [],
    unread_messages: [],
  }),
  async mounted() {
    this.user = await this.getUserInfo();
    this.location = await this.getLocationInfo();
    this.business = await this.getBusinessInfo();
    this.getConversations();
  },
  methods: {
    getUserInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .get()
          .then((user) => {
            resolve({ id: user.id, ...user.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocationInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("businesses")
          .doc(this.user.business_id)
          .collection("locations")
          .doc(this.user.location_id)
          .get()
          .then((location) => {
            resolve({ id: location.id, ...location.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBusinessInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("businesses")
          .doc(this.user.business_id)
          .get()
          .then((business) => {
            resolve({ id: business.id, ...business.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getUnreadMessages(id, data) {
      let index = 0;
      const querySnapshot = await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .collection("locations")
        .doc(this.user.location_id)
        .collection("messages")
        .where("customer_number", "==", data.customer_number)
        .where("to_from", "==", "customer")
        // .orderBy("timestamp", "asc")
        .get();

      console.info("data are", data);
      querySnapshot.forEach((doc) => {
        if (
          doc.data() &&
          doc.data().unread_by &&
          doc.data().unread_by.includes(this.user.id)
        ) {
          index++;
        }
      });

      this.conversations.push({
        id: id,
        ...data,
        unread_count: index,
      });
    },
    getConversations() {
      firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .collection("locations")
        .doc(this.user.location_id)
        .collection("conversations")
        .orderBy("last_message_timestamp", "desc")
        .onSnapshot((docs) => {
          this.conversations = [];
          docs.forEach(async (doc) => {
            await this.getUnreadMessages(doc.id, doc.data());
          });

          this.conversations.reverse();
        });
    },
    transformDate(seconds) {
      let date = new Date(0);
      date.setUTCSeconds(seconds);
      const ISOdate = date.toISOString();

      return DateTime.fromISO(ISOdate)
        .setZone("America/Detroit")
        .toFormat("LLL. dd, yyyy hh:mm a");
    },
  },
};
</script>
