<template>
  <MessagingConversationsComponent />
</template>

<script>
import MessagingConversationsComponent from '../components/MessagingConversationsComponent.vue'

export default {
  name: 'MessagingConversations',
  components: {
    MessagingConversationsComponent,
  },
}
</script>
