import { render } from "./MessagingConversations.vue?vue&type=template&id=f5bbe734"
import script from "./MessagingConversations.vue?vue&type=script&lang=js"
export * from "./MessagingConversations.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f5bbe734"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f5bbe734', script)) {
    api.reload('f5bbe734', script)
  }
  
  module.hot.accept("./MessagingConversations.vue?vue&type=template&id=f5bbe734", () => {
    api.rerender('f5bbe734', render)
  })

}

script.__file = "src/views/MessagingConversations.vue"

export default script